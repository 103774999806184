.option-show {
  position: absolute;
  transform: translate3d(-130px, -107px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
}

.badge-in-progress {
  background-color: #ffc107; /* Color ámbar claro */
  color: white;
}
