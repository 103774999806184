.link-button {
  background: none;
  border: none;
  color: #007bff; /* Cambia el color si es necesario */
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font: inherit;
}

.btn-contact {
  background: #007bff;
  border: none;
  color: #ffffff; /* Cambia el color si es necesario */
  cursor: pointer;
  padding: 20;
  font: inherit;
}
