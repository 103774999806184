.logo {
  width: 100%;
}
.sidebar-left {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.logo-aws {
  transition: all 0.3s ease;
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logo-aws img {
  width: 100px;
  display: block;
  opacity: 0.8;
  transition: all 0.3s ease;
}

.vertical.collapsed .logo-aws img {
  width: 50px;
}
