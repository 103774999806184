.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  /* background: rgb(25,25,25); */
  background-color: #fff;
  color: #000000;
  padding: 20px;
  box-sizing: border-box;
}

.error-icon {
  font-size: 5rem;
  margin-bottom: 1rem;
}

.error-heading {
  font-size: 6rem;
  margin: 0;
  color: #000000;
}

.error-message {
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.error-link {
  font-size: 1rem;
  color: #000000;
  text-decoration: none;
  background-color: #FDCC10;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.error-link:hover {
  background-color: #ffe063;
  text-decoration: none;
}
